import React, { useState } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import logo from "../assets/logo.png";
import { useLocation } from "react-router-dom";
import Marquee from "./Marquee";

const Header = () => {
  const location = useLocation();
  const [expandedMenu, setExpandedMenu] = useState(null);

  const handleMenuHover = (eventKey) => {
    setExpandedMenu(eventKey);
  };

  const isSubMenuActive = (subMenuPaths) => {
    return subMenuPaths.includes(location.pathname);
  };

  const activeStyle = {
    borderBottom: "3px solid white",
    fontWeight: "bold",
    color: "black",
  };

  const inactiveStyle = { fontWeight: "bold", color: "black" };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="xl"
        style={{ backgroundColor: "#3CA3DC" }}
        className="header"
      >
        <Container>
          <Navbar.Brand href="/">
            <img
              src={logo}
              width="180"
              height="50"
              className="d-inline-block align-top"
              alt="Logo"
              style={{ backgroundColor: "white", borderRadius: "20px" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mx-auto headerItems" style={{ fontSize: "20px" }}>
              <Nav.Link
                href="/home"
                className="menu-animation-text"
                style={
                  location.pathname === "/home" ? activeStyle : inactiveStyle
                }
              >
                Головна
              </Nav.Link>

              <NavDropdown
                title="Про нас"
                id="nav-dropdown-about"
                className="menu-animation-text"
                style={
                  isSubMenuActive([
                    "/about",
                    "/principleEducation",
                    "/loyaltySystem",
                    "/legalInfo",
                  ])
                    ? activeStyle
                    : inactiveStyle
                }
                show={expandedMenu === "about"}
                onMouseEnter={() => handleMenuHover("about")}
                onMouseLeave={() => handleMenuHover(null)}
              >
                <div className="colorDropDownMenu">
                  <NavDropdown.Item
                    href="/about"
                    className="BGDropDownMenuItem"
                  >
                    Про нас
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/principleEducation">
                    Принципи навчання
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/loyaltySystem">
                    Система лояльності
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/legalInfo">
                    Публічна оферта
                  </NavDropdown.Item>
                </div>
              </NavDropdown>
              <NavDropdown
                title="Локації"
                id="nav-dropdown-location"
                className="menu-animation-text"
                style={
                  isSubMenuActive(["/kyivLocation", "/rivneLocation"])
                    ? activeStyle
                    : inactiveStyle
                }
                show={expandedMenu === "location"}
                onMouseEnter={() => handleMenuHover("location")}
                onMouseLeave={() => handleMenuHover(null)}
              >
                <NavDropdown.Item href="/kyivLocation">Київ</NavDropdown.Item>
                <NavDropdown.Item href="/rivneLocation">Рівне</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                href="/courses"
                className="menu-animation-text"
                style={
                  location.pathname === "/courses" ? activeStyle : inactiveStyle
                }
              >
                Курси
              </Nav.Link>
              <NavDropdown
                className="menu-animation-text"
                title="Розклад"
                id="basic-nav-dropdown"
                style={
                  isSubMenuActive([
                    "/miniKindergartenC",
                    "/littleExplorers",
                    "/skilledProgrammers",
                    "/preparationForSchool",
                    "/extendedDayGroup14",
                    "/mathematicsInProgramming",
                    "/weDoRobotics",
                    "/fundamentalsOfProgramming",
                    "/minecraft",
                    "/extendedDayGroup59",
                    "/roblox",
                    "/roboticsMindstorms",
                    "/webProgramming",
                    "/preparationForNMT",
                  ])
                    ? activeStyle
                    : inactiveStyle
                }
                show={expandedMenu === "schedule"}
                onMouseEnter={() => handleMenuHover("schedule")}
                onMouseLeave={() => handleMenuHover(null)}
              >
                <NavDropdown
                  className="NavDropDownItem"
                  title="Київ"
                  id="kyiv-nav-dropdown"
                >
                  <NavDropdown
                    className="NavDropDownItem"
                    title="Дослідник (3-6 років)"
                  >
                    <NavDropdown.Item href="/miniKindergartenC">
                      Міні садок (3-5 років)
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/littleExplorers">
                      Маленькі дослідники (3-4 роки)
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/skilledProgrammers">
                      Юні програмісти (5-6 років)
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/preparationForSchool">
                      Підготовка до школи (5-6 років)
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    className="NavDropDownItem"
                    title="Колекціонер знань (1-4 клас)"
                  >
                    <NavDropdown.Item href="/extendedDayGroup14">
                      Група подовженого дня (6-10 років)
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/mathematicsInProgramming">
                      Програмування в дії (1-2 клас)
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/weDoRobotics">
                      Робототехніка WeDo (1-2 клас)
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/fundamentalsOfProgramming">
                      Основи програмування (2-3 клас)
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/minecraft">
                      Minecraft (3-4 клас)
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    className="NavDropDownItem"
                    title="Знавець (5-11 клас)"
                  >
                    <NavDropdown.Item href="/extendedDayGroup59">
                      Група продовженого дня (10-13 років)
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/roblox">
                      Roblox (4-5 клас)
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/roboticsMindstorms">
                      Робототехніка Mindstorms (5-7 клас)
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/webProgramming">
                      WEB програмування (5-9 клас)
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/preparationForNMT">
                      Підготовка до НМТ (10-11 клас)
                    </NavDropdown.Item>
                  </NavDropdown>
                </NavDropdown>
                <NavDropdown
                  className="NavDropDownItem"
                  title="Рівне"
                  id="rovno-nav-dropdown"
                >
                  <NavDropdown
                    className="NavDropDownItem"
                    title="Дослідник (3-6 років)"
                  >
                    <NavDropdown.Item href="/rivneLittleExplorers">
                      Маленькі дослідники (3-4 роки)
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/rivneSkilledProgrammers">
                      Юні програмісти (5-6 років)
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/rivnePreparationForSchool">
                      Підготовка до школи (5-6 років)
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    className="NavDropDownItem"
                    title="Колекціонер знань (1-4 клас)"
                  >
                    <NavDropdown.Item href="/rivneWeDoRobotics">
                      Робототехніка WeDo (1-2 клас)
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/rivneMinecraft">
                      Minecraft (3-4 клас)
                    </NavDropdown.Item>
                  </NavDropdown>
                </NavDropdown>
              </NavDropdown>

              <Nav.Link
                href="/minikindergarten"
                className="menu-animation-text"
                style={
                  location.pathname === "/minikindergarten"
                    ? activeStyle
                    : inactiveStyle
                }
              >
                Міні-садок
              </Nav.Link>
              <Nav.Link
                href="/contactss"
                className="menu-animation-text"
                style={
                  location.pathname === "/contactss"
                    ? activeStyle
                    : inactiveStyle
                }
              >
                Контакти
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Marquee />
    </>
  );
};

export default Header;
