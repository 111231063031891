import React from "react";
import "../styles/Marquee.css";
import { FcAdvertising } from "react-icons/fc";

const Marquee = () => {
  return (
    <div className="marquee">
      <p>
        <FcAdvertising
          style={{
            filter: "invert(0%) sepia(100%) saturate(1000%) hue-rotate(0deg)",
            fontSize: "30px",
          }}
        />
        &nbsp;&nbsp; "Осінній табір" в TechKids Academy з{" "}
        <span style={{ fontWeight: "bold" }}>28.10</span> по{" "}
        <span style={{ fontWeight: "bold" }}> 01.11</span>. Знижки на раннє
        бронювання! Телефонуй або пиши у соц.мережі:{" "}
        <a
          href="tel:+380684043304"
          style={{ color: "black", fontWeight: "bold" }}
        >
          +38 (068) 404-33-04
        </a>
        &nbsp;&nbsp;
        <FcAdvertising
          style={{
            filter: "invert(0%) sepia(100%) saturate(1000%) hue-rotate(0deg)",
            fontSize: "30px",
            transform: "scaleX(-1)",
          }}
        />
      </p>
    </div>
  );
};

export default Marquee;
